@font-face {
  font-family: "helvetica-neue-bold";
  src: url("../../fonts/Helvetica\ Neu\ Bold.ttf") format("truetype");
}

@font-face {
  font-family: "circlular-std-book";
  src: url("../../fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: "circlular-std-bold";
  src: url("../../fonts/CircularStd-Bold.otf") format("opentype");
}

@font-face {
  font-family: "sf-pro";
  src: url("../../fonts/SF-Pro-Text-Thin.otf") format("opentype");
}

nav {
  background-color: #39393a;
}

.navbar-link {
  font-family: "circlular-std-book" !important;
  font-style: normal;
  font-size: 20px;
  color: #d17a22 !important;
  transition: all 0.15s ease 0s;
}

.navbar-link:hover {
  font-family: "circlular-std-book" !important;
  font-style: normal;
  font-size: 20px;
  color: #e59500 !important;
}

#logo {
  position: absolute;
  left: 2.99%;
  right: 93.75%;
  top: 17.14%;
  bottom: 17.14%;

  border: 4px solid #d17a22;
  box-sizing: border-box;

  font-family: "circlular-std-bold" !important;
  font-style: normal;
  font-size: 50px;
  line-height: 51px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  
  color: #d17a22;
}
