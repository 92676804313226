@font-face {
  font-family: "circular-std-book";
  src: url("../../fonts/CircularStd-Book.ttf") format("truetype");
}

@font-face {
  font-family: "circular-std-med";
  src: url("../../fonts/CircularStd-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "circular-std-bold";
  src: url("../../fonts/CircularStd-Bold.otf") format("opentype");
}

.profile-pic {
  width: 90%;
}

#main-page {
  background-color: #39393a/*#ebf4f6*/;
  padding-top: 10%;
  padding-left: 2%;
  padding-right: 2%;
  padding-bottom: 6%;
  height: 99vh;
}

#profile-circle {
  /*height: 100%;
  width: 100%;*/
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 50%;
  background-color: #d17a22;
  overflow: hidden;
  padding-top: 5.5%;
  padding-bottom: 5.5%;
  padding-right: 1%;
  padding-left: 1%;
  text-align: center;
}

.title-text-1 {
  font-family: "circular-std-bold";
  font-size: 70px;
  color: #d17a22;
  margin-left: 10%;
  padding-left: 5%;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(20vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.body-text-1 {
  font-family: "circular-std-book";
  font-size: 18px;
  color: #e6e6e6;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  padding-left: 5%;
  text-align: center;
}

.body-text-1-onyx {
  font-family: "circular-std-book";
  font-size: 18px;
  color: #39393a;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-left: 5%;
  margin-right: 5%;
  padding-left: 5%;
}

.scroll-button {
  margin-top: 7%;
  margin-bottom: 0%;
  margin-right: 2%;
  margin-left: 6.5%;
}

.scroll-button-2 {
  margin-top: 13%;
  margin-bottom: 0%;
  margin-right: 0%;
  margin-left: 0%;
}

.scroll-button-3 {
  margin-top: 5%;
  margin-bottom: 0%;
  margin-right: 0%;
  margin-left: 0%;
}

.button-dark-orange {
  width: 213px;
  height: 56px;
  border: 4px solid #d17a22;
  box-sizing: border-box;
  border-radius: 50px;
  text-align: center;
  font-family: "circular-std-bold";
  font-size: 25px;
  color: #d17a22 !important;
  padding-block-start: 2%;
  transition: box-shadow 0.3s;
}

.button-dark-orange-2 {
  width: 300px;
  height: 56px;
  border: 4px solid #d17a22;
  box-sizing: border-box;
  border-radius: 50px;
  text-align: center;
  font-family: "circular-std-bold";
  font-size: 25px;
  color: #d17a22 !important;
  padding-block-start: 2%;
  transition: box-shadow 0.3s;
}

.button-dark-orange:hover {
  box-shadow: 0 0 30px rgba(33, 33, 33, 0.2);
  background-color: #e6e6e6;
  cursor: pointer;
}

.button-dark-orange-2:hover {
  box-shadow: 0 0 30px rgba(33, 33, 33, 0.2);
  background-color: #39393a;
  cursor: pointer;
}

.button-light-blue {
  width: 213px;
  height: 56px;
  border-radius: 50px;
  background: #58668b;
  margin-top: 2%;
  margin-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%;
  text-align: center;
  font-family: "circular-std-bold";
  font-size: 25px;
  color: #ffffff;
  padding-block-start: 1%;
  transition: box-shadow 0.3s;
}

.button-light-blue:hover {
  box-shadow: 0 0 15px rgba(33, 33, 33, 0.3);
}

#skills-brief {
  background-color: #e6e6e6;
  padding-top: 8%;
  padding-bottom: 10%;
}

.title-text-2 {
  font-family: "circular-std-med";
  font-size: 60px;
  color: #d17a22;
  margin-left: 5%;
  margin-bottom: 5%;
}

.title-text-3 {
  font-family: "circular-std-med";
  font-size: 30px;
  color: #d17a22;
  margin-left: 5%;
  margin-bottom: 5%;
}

.title-text-4 {
  font-family: "circular-std-med";
  font-size: 45px;
  color: #d17a22;
  padding-bottom: 3%;
}
.title-text-4-contact {
  font-family: "circular-std-med";
  font-size: 45px;
  color: #d17a22;
}

#skills-tools-box {
  border: 7px solid #d17a22/*#76b4bd*/;
  box-sizing: border-box;
  border-radius: 30px;
  width: 120%;

  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  padding-bottom: 3%;
}

.skills-language-parent {
  padding-top: 6%;
  padding-bottom: 6%;
}

#skills-col-2 {
  padding-top: 27%;
  padding-left: 10%;
}

.skills-icon {
  width: 35%;
  padding-right: 3%;
}

.skills-icon-name {
  font-family: "circular-std-bold";
  font-size: 28px;
  padding: 7% 0;
  color: #39393a;
}

#projects-brief {
  background-color: #39393a;
  padding-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 6%;
}

#email-link {
  font-family: "helvetica-neue-bold" !important;
  font-style: normal;
  color: #d17a22 !important;
}

.text-box-projects {
  width: 100%;
}

.project-brief-section {
  padding-top: 4%;
  padding-bottom: 2%;
  text-align: center;
}

.project-brief-box {
  width: 100%;
  background: #d17a22;
  border-radius: 20px;

  padding-top: 0%;
  padding-bottom: 1%;
  padding-left: 1%;
  padding-right: 1%;

  display: inline-block;
  vertical-align: middle;
}

.project-brief-text {
  font-family: "circular-std-book";
  color: #e6e6e6;
  padding: 1% 0;
}

.project-brief-title {
  font-family: "circular-std-med";
  width: 20%;
  font-size: 27px;
}

.project-brief-body {
  width: 50%;
  font-size: 16px;
  text-align: left;
}

.align-right {
  width: 30%;
  text-align: end;
}

.project-link {
  font-family: "circular-std-book" !important;
  font-size: 18px;
  color: #e6e6e6 !important;
  transition: all 0.15s ease 0s;
}

.project-link:hover {
  font-family: "circular-std-book" !important;
  font-style: normal;
  font-size: 18px;
  color: #bfcdd6 !important;
}

#contact-info {
  background-color: #e6e6e6;
  padding-top: 3%;
  padding-left: 3%;
  padding-right: 3%;
  padding-bottom: 3%;
}

#contact-info-bar {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 45%;
}

.home-handle-icon {
  width: 55px;
  transition: all 0.1s ease 0s;
}

.home-handle-icon:hover {
  width: 60px;
}
